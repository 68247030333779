<template>
  <div class="full" id="apply">
    <div class="green">
      <van-nav-bar title="提交订单" >
        <template #left>
          <van-icon :size="24" color="#ffffff" name="arrow-left" slot="left" @click="exit()" />
        </template>
      </van-nav-bar>
      <div class="green_bg"></div>
      <div class="p_r out">
        <!--time-->
        <div class="so_out so_out_top">
          <div class="title">{{name}}</div>
          <div class="sub">
            <span v-if="day">使用日期：{{day}}</span>
            <span v-if="time">时间：{{time}}</span>
          </div>
        </div>
        <!--购买数量-->
        <div class="so_out so_out_num">
          <div class="d_f ali_c mb_12">
            <span class="title flex_1">{{typeName}}</span>
            <span class="price">￥{{price ? price/100 : 0}}</span>
          </div>
          <div class="d_f">
            <span class="flex_1 sub_title">{{remark}}</span>
            <!--<div class="d_f ali_c so_buy_num">
              <img src="./img/so_d_dis.png" alt="" v-if="buyNum < 2">
              <img class="cur_p" src="./img/so_d.png" v-else alt="" @click="buyNum&#45;&#45;">
              <span>{{buyNum}}</span>
              <img class="cur_p" src="./img/so_u.png" alt="" @click="buyNum++">
            </div>-->
          </div>
        </div>
        <!--出行人信息-->
        <div class="so_out so_person">
          <div class="d_f ali_b">
            <div class="title">出行人信息</div>
            <div class="sub_title">需要添加1位成人游客</div>
          </div>
          <div class="so_person_choose p_r d_f" v-for="(item,index) in checkArr" :key="index">
            <div class="sop_detail flex_1">
              <div class="name">{{item.linkManName}}</div>
              <div>身份证号码：{{item.cardNum}}</div>
            </div>
            <div class="d_f ali_c j_s">
              <img class="per_delete cur_p" src="./img/so_per_delete.png" alt="" @click="deletePer(index)">
              <img class="per_edit cur_p" src="./img/so_per_edit.png" alt="" @click="editPer(item,index)">
            </div>
            <div class="tag">游客{{index+1}}</div>
          </div>
          <div class="so_person_choose p_r d_f j_s" v-if="checkArr.length == 0">
            <div class="tag">游客{{checkArr.length+1}}</div>
            <div class="per_btn d_f ali_c has cur_p" @click="showPersonPop = true">
              <div class="flex_1 t_a">
                <img src="./img/per_per.png" alt="">
                <span>选择已有游客</span>
              </div>
            </div>
            <div class="per_btn d_f ali_c has_not cur_p" @click="addNewPer">
              <div class="flex_1 t_a">
                <img src="./img/per_add.png" alt="">
                <span>添加新游客</span>
              </div>
            </div>
          </div>
        </div>
        <!--联系人信息-->
        <div class="so_out so_contact">
          <div class="d_f ali_c">
            <span class="title">联系人信息</span>
            <span class="sub_title">接收短信通知</span>
          </div>
          <div class="d_f ali_b so_phone">
            <div class="phone_title">联系电话</div>
            <input v-model="submitInfo.telephone" type="text" class="flex_1" placeholder="请输入" maxlength="11">
          </div>
        </div>
        <!--会员积分-->
        <div class="so_out so_integral" v-show="pointUseSwitch && canUseIntegral && integral > 0">
          <div class="title d_f ali_b j_s">
            <span>积分优惠</span>
            <span class="jf_link">《<a @click="showJfPop = true">积分规则</a>》</span>
          </div>
          <div>
            <div class="sub">
              <span>积分抵扣</span>
              <span>本单可使用 {{showTotalIntegral}} 积分 抵扣 {{showIntegralMoney / 100}} 元</span>
              <span class="check" :class="useIntegral?'checked':''" @click="integralCheck()"></span>
            </div>
          </div>
        </div>

      </div>
      <!--明细弹窗-->
      <div class="so_detail_out" v-if="showDetailPop">
        <div class="so_detail">
          <div class="head">订单明细</div>
          <div class="d_f ali_c j_s content">
            <span>{{name}} x{{checkArr.length}}</span>
            <span v-show="useIntegral"><div class="reduce">积分抵扣 减{{integralMoney / 100}}</div></span>
            <span>￥{{price ? (totalPrice - integralMoney)/100 :0}}</span>
          </div>
          <!--<div class="d_f ali_c j_s content">
            <span>{{name}}</span>
            <span>￥<span>{{price ? price/100 :0}}</span> x <span>{{buyNum}}</span></span>
            <span>￥{{price ? price*buyNum/100 :0}}</span>
          </div>-->
        </div>
      </div>
      <!--底部按钮-->
      <div class="btn_out d_f ali_c" v-if="isKeyPop">
        <div class="flex_1 d_f integral_f">
          <div class="l d_f">
            <div class="title">总金额</div>
            <div class="price">￥{{price ? (totalPrice - integralMoney) / 100 :0}}</div>
            <div class="detail_pop d_f ali_c cur_p" v-if="!showDetailPop" @click="showDetailPop = true">
              <span>明细</span>
              <img src="./img/so_pop.png" alt="">
            </div>
            <div class="detail_pop d_f ali_c cur_p" v-if="showDetailPop" @click="showDetailPop = false">
              <span>收起</span>
              <img src="./img/so_down.png" alt="">
            </div>
          </div>
          <div class="integral" v-show="useIntegral">
            <li>积分抵扣</li>
            <p>-￥{{integralMoney / 100}}</p>
          </div>
        </div>
        <div class="btn_pay cur_p" @click="pay">立即支付</div>
      </div>
      <!--选择出行人-->
      <div class="so_detail_out so_per" v-if="showPersonPop">
        <div class="so_detail">
          <div class="head d_f ali_c j_s">
            <div class="cur_p" @click="showPersonPop = false">取消</div>
            <div class="t_a">请选择游客</div>
            <div class="cur_p" @click="surePerson">确定</div>
          </div>
          <div class="contentPer">
            <van-checkbox-group v-model="result">
              <div class="cp_item d_f ali_c" v-for="(item,i) in perArr" :key="i">
                <van-checkbox icon-size="15px" shape="square" checked-color="#3FD467" :name="item.linkManId"></van-checkbox>
                <div class="name">{{item.linkManName}}</div>
                <div class="flex_1">{{item.cardNum}}</div>
                <div class="edit cur_p" @click="editPer(item)">
                  <img class="full_w" src="./img/so_per_edit.png" alt="">
                </div>
              </div>
            </van-checkbox-group>

          </div>
        </div>
      </div>
    </div>
    <!--积分规则弹窗-->
    <jfRules v-if="showJfPop" @close="showJfPop = false"></jfRules>
  </div>
</template>

<script>
  import { NavBar, Icon, Checkbox, CheckboxGroup, Toast} from 'vant'
  import jfRules from "./jfRules";
  export default {
    name: "submitOrderSnow",
    components:{
      'van-nav-bar': NavBar,
      'van-icon': Icon,
      'van-checkbox': Checkbox,
      'van-checkbox-group': CheckboxGroup,
      jfRules,
    },
    watch: {
      checkArr: { // 深度监听
        handler(val, oldVal){
          if(this.isRealName) {
            this.personIntegral()
          }
        },
        deep:true
      },
      buyNum: { // 深度监听
        handler(val, oldVal){
          this.personIntegral()
        },
        deep:true
      }
    },
    data() {
      return {
        apis:{
          perList:'/userLinkMan/slicedQueryLinkMan',
          pay:'/stlsOrder/create',
          findOrderDetail:'/stlsOrder/findOrderDetail',
          findCommodity: '/commodity/findCommodity', // 查询商品详情
          queryPoint:'/pointCenter/queryPoint', // 查询可用积分
          getSysPointConfig: 'point/getSysPointConfig' // 查询积分比例
        },
        showJfPop:false,
        showDetailPop:false,//明细弹窗
        showPersonPop:false,//选择出行人
        buyNum: 1,//购买数量
        result:[],//选择出行人checkbox数组,
        name:this.$route.query.name,
        typeName:this.$route.query.n,
        price:this.$route.query.p,
        totalPrice: 0, // 总金额
        time:this.$route.query.t,
        day:this.$route.query.d.replace(/:/g,'-'),
        remark:this.$route.query.r,
        submitInfo:{
          date:this.$route.query.d.replace(/:/g,'-'),
          linkmanName:'游客',
          orderType:'1',
          remark:'',
          source:null,
          telephone:'',
          reqOrderDetails:[
            /*{
              certificateNumber:'',
              certificateType:'',
              commodityId:this.$route.query.cTimeId,
              visitorName:'',
            }*/
          ],
          token:this.$global.token,
        },
        perArr:[],//联系人数组
        checkArr: [],
        isClick:true,
        isKeyPop:true,//键盘是否弹起
        isSnow:sessionStorage.getItem('isSnow'),
        pointUseSwitch: 0, // 能否使用积分 商品
        canUseIntegral: false, // 能否使用积分 总开关
        integral: 0, // 可使用积分
        integralValue: 0, // 单个商品可使用积分
        useIntegral: false, // 是否使用积分
        totalIntegral: 0, // 总共能兑换的积分
        integralExchangeMoneyScale: 1, //积分比列
        integralMoney: 0, // 积分兑换金额
        showTotalIntegral: 0, // 提示 本次使用可使用积分
        showIntegralMoney: 0, // 提示 本次积分抵扣金额
        isRealName: JSON.parse(this.$route.query.realName) // 是否实名制
      }
    },
    activated(){
      this.buyNum = sessionStorage.getItem('buyNum') ? sessionStorage.getItem('buyNum') : 1;
      this.submitInfo.telephone = sessionStorage.getItem('telephone') ? JSON.parse(sessionStorage.getItem('telephone')) : '';
    },
    methods: {
      //立即支付
      pay(){
        let _t = this;
        let regPhone = /^1[3456789]\d{9}$/;//电话正则
        if(_t.isRealName){
          if(!_t.checkArr.length) return Toast('请选择出行人');
          if(_t.checkArr.length != _t.buyNum) return Toast('还需选择'+(_t.buyNum-_t.checkArr.length)+'个出行人');
        }
        if(!_t.checkArr.length) return Toast('请选择出行人');
        if(!_t.submitInfo.telephone) return Toast('请输入联系电话');
        if(!regPhone.test(_t.submitInfo.telephone)) return Toast('电话输入有误');
        _t.submitInfo['totalDiscountPoints'] = _t.totalIntegral
        _t.submitInfo['isRealName'] = _t.isRealName
        _t.submitInfo.reqOrderDetails = [];
        for(let i = 0;i<_t.buyNum;i++){
          _t.checkArr.map((item,index) => {
            let obj = {
              certificateNumber:item.cardNum,
              certificateType:0,
              linkManId:item.linkManId,
              commodityId:this.$route.query.id,
              visitorName:item.linkManName,
              discountPoints: item.integralValue,
            };
            _t.submitInfo.reqOrderDetails.push(obj);
          });
        }
        _t.$post({
          url:_t.apis.pay,
          params: _t.submitInfo
        }).then(res => {
          _t.$global.isAddPerson = false;
          sessionStorage.setItem('buyNum',this.buyNum);
          sessionStorage.setItem('telephone',this.submitInfo.telephone);
          _t.$router.push({
            path:'/payWay',
            query:{
              code:res.data,
              price:_t.price,
              buyNum:_t.buyNum,
            }
          })
        }).catch(err => {
          Toast(err.message);
        });
      },
      //删除已选联系人
      deletePer(index){
        let _t = this;
        _t.checkArr.splice(index,1);
        _t.result.splice(index,1);
        sessionStorage.setItem('checkArr',JSON.stringify(_t.checkArr));
        sessionStorage.setItem('result',JSON.stringify(_t.result));
      },
      //编辑新乘客
      editPer(item){
        sessionStorage.setItem('buyNum',this.buyNum);
        sessionStorage.setItem('telephone',this.submitInfo.telephone);
        sessionStorage.setItem('linkManId',JSON.stringify(item.linkManId));
        sessionStorage.setItem('checkArr',JSON.stringify(this.checkArr));
        sessionStorage.setItem('result',JSON.stringify(this.result));
        this.gotoPer('edit',item.linkManId,item.userId)
      },
      //添加新乘客
      addNewPer(){
        sessionStorage.setItem('buyNum',this.buyNum);
        sessionStorage.setItem('telephone',this.submitInfo.telephone);
        this.gotoPer('add')
      },
      gotoPer(type,key,userId){
        this.$router.push({
          path:'/person',
          query:{
            key:key,
            userId:userId,
            type:type
          }
        })
      },
      //选择出行人确定
      surePerson(){
        let _t = this;
        if(_t.result.length>1) return Toast('您只需选择1个出行人');
        _t.perArr.map((item,index) => {
          _t.result.map((it,idx) => {
            if(item.linkManId === it){
              item['integralValue'] = 0 // 设置抵扣积分
              item['integralMoney'] = 0 // 设置抵扣金额
              _t.checkArr.push(item);
            }
          })
        });
        let hash = {};
        _t.checkArr = _t.checkArr.reduce((preVal, curVal) => {
          hash[curVal.linkManId] ? '' : hash[curVal.linkManId] = true && preVal.push(curVal);
          return preVal
        }, []);
        _t.checkArr = JSON.parse(JSON.stringify(_t.checkArr));
        sessionStorage.setItem('checkArr',JSON.stringify(_t.checkArr));
        sessionStorage.setItem('result',JSON.stringify(_t.result));
        _t.showPersonPop = false;
      },
      //获取联系人列表
      getPerList(){
        let me = this;
        me.$post({
          url:me.apis.perList,
          params: {
            pageIndex: 1,
            pageSize: 100,
            token: me.$global.token
          }
        }).then(res => {
          this.perArr = res.data.data;
          let linkManId = sessionStorage.getItem('linkManId');
          let checkIndex = null;
          this.checkArr.map((item,index) => {
            this.result.push(item.linkManId);
          });
          if(linkManId){
            this.checkArr.map((item,index) => {
              if(item.linkManId == linkManId){
                checkIndex = index;
              }
            });
            this.perArr.map((item,index) => {
              if(item.linkManId == linkManId){
                this.checkArr[checkIndex] = item;
              }
            })
          }
          if(this.$global.isAddPerson){
            this.checkArr.push(this.perArr[0]);
            this.surePerson();
          }
        });
      },
      // 查询商品详情
      findCommodity(){
        let me = this;
        me.$get({
          url: me.apis.findCommodity,
          params: {
            token: me.$global.token,
            commodityId: me.$route.query.id
          }
        }).then(res => {
          // console.log(res)
          me.pointUseSwitch = res.pointUseSwitch
          me.integralValue = res.creditPointsNum
          // pointUseSwitch: JSON.parse(this.$route.query.i), // 能否使用积分 商品
          // integralValue: JSON.parse(this.$route.query.u), // 单个商品可使用积分
          me.personIntegral()
        });
      },
      // 查询积分比列
      getSysPointConfig(){
        let me = this;
        me.$post({
          url:me.apis.getSysPointConfig,
          params: {}
        }).then(res => {
          // console.log(res)
          me.integralExchangeMoneyScale = res.data.integralExchangeMoneyScale
          if(res.data.isEnableIntegralUse == 1){
            me.canUseIntegral = true
          }
          this.personIntegral()
        });
      },
      exit(){
        this.$global.isAddPerson = false;
        this.$router.go(-1)
      },

      // 计算每一个人使用的积分
      personIntegral() {
        this.price = parseInt(this.price)

        /*if(!this.isRealName){
          this.checkArr = []
          for(let i = 0; i < this.buyNum; i++){
            this.checkArr.push({
              cardNum: '',
              cardType: '',
              integralMoney: 0,
              integralValue: 0,
              linkManId: '',
              linkManName: '',
              phoneNum: '',
              userId: '',
              version: ''
            })
          }
        }*/
        this.totalPrice = this.price *1
        // 查看单个用户适用积分
        if(this.useIntegral){
          let integral = this.integral
          for(let i = 0; i < this.checkArr.length; i++){
            if (integral > this.integralValue) { // 剩余可用积分大于单个使用积分时
              this.checkArr[i].integralValue = this.integralValue
              if(this.integralValue * this.integralExchangeMoneyScale > this.price){ // 当积分兑换金额大于单价时
                this.checkArr[i].integralMoney = this.price
              }else{
                this.checkArr[i].integralMoney = this.integralValue * this.integralExchangeMoneyScale
              }
              integral -= this.integralValue
            } else { // 剩余可用积分，小于单个使用积分
              if(integral > 0){
                this.checkArr[i].integralValue = integral
                if(integral * this.integralExchangeMoneyScale > this.price){
                  this.checkArr[i].integralMoney = this.price
                }else{
                  this.checkArr[i].integralMoney = integral * this.integralExchangeMoneyScale
                }
                integral = 0
              }else{
                this.checkArr[i].integralValue = 0
                this.checkArr[i].integralMoney = 0
              }
            }
          }
        }else{
          for(let i = 0; i < this.checkArr.length; i++){
            this.checkArr[i].integralValue = 0
            this.checkArr[i].integralMoney = 0
          }
        }
        let totalIntegral = 0, integralMoney = 0; // 总共使用积分     积分抵扣总金额
        for(let i = 0; i < this.checkArr.length; i++){
          totalIntegral += this.checkArr[i].integralValue
          integralMoney += this.checkArr[i].integralMoney
        }
        this.totalIntegral =  totalIntegral
        this.integralMoney =  integralMoney
        // 按购票人循环
        /*
        if(this.checkArr.length > 0){ // 当有购票人时
          let integral = this.integral, showTotalIntegral = 0, showIntegralMoney = 0;
          for(let i = 0; i < this.checkArr.length; i++){
            if (integral > this.integralValue) { // 剩余可用积分大于单个使用积分时
              if(this.integralValue * this.integralExchangeMoneyScale > this.price){ // 当积分兑换金额大于单价时
                showIntegralMoney += this.price
              }else{
                showIntegralMoney += this.integralValue * this.integralExchangeMoneyScale
              }
              showTotalIntegral += this.integralValue
              integral -= this.integralValue
            } else { // 剩余可用积分，小于单个使用积分
              if(integral > 0){
                showTotalIntegral += integral
                if(integral * this.integralExchangeMoneyScale > this.price){
                  showIntegralMoney += this.price
                }else{
                  showIntegralMoney += integral * this.integralExchangeMoneyScale
                }
                integral = 0
              }else{
                showTotalIntegral += 0
                showIntegralMoney += 0
              }
            }
          }
          this.showTotalIntegral = showTotalIntegral
          this.showIntegralMoney = showIntegralMoney
        }else{
          if (this.integral > this.integralValue) {
            this.showTotalIntegral = this.integralValue
            if(this.integralValue * this.integralExchangeMoneyScale > this.price){ // 当积分兑换金额大于单价时
              this.showIntegralMoney = this.price
            }else{
              this.showIntegralMoney = this.integralValue * this.integralExchangeMoneyScale
            }
          }else {
            this.showTotalIntegral = this.integral
            if(this.integralValue * this.integralExchangeMoneyScale > this.price){ // 当积分兑换金额大于单价时
              this.showIntegralMoney = this.price
            }else{
              this.showIntegralMoney = this.integralValue * this.integralExchangeMoneyScale
            }
          }
        }*/

        // 按设置的购票人数量计算
        let integral = this.integral, showTotalIntegral = 0, showIntegralMoney = 0;
        console.log(1)
        for(let i = 0; i < this.buyNum; i++){
          if (integral > this.integralValue) { // 剩余可用积分大于单个使用积分时
            if(this.integralValue * this.integralExchangeMoneyScale > this.price){ // 当积分兑换金额大于单价时
              showIntegralMoney += this.price
            }else{
              showIntegralMoney += this.integralValue * this.integralExchangeMoneyScale
            }
            showTotalIntegral += this.integralValue
            integral -= this.integralValue
          } else { // 剩余可用积分，小于单个使用积分
            if(integral > 0){
              showTotalIntegral += integral
              if(integral * this.integralExchangeMoneyScale > this.price){
                showIntegralMoney += this.price
              }else{
                showIntegralMoney += integral * this.integralExchangeMoneyScale
              }
              integral = 0
            }else{
              showTotalIntegral += 0
              showIntegralMoney += 0
            }
          }
        }
        this.showTotalIntegral = showTotalIntegral
        this.showIntegralMoney = showIntegralMoney
      },

      // 是否使用积分选择
      integralCheck() {
        this.useIntegral = !this.useIntegral
        this.personIntegral()
      },
      // 查询可用积分
      queryPoint() {
        let me = this;
        me.$post({
          url:me.apis.queryPoint,
          params: {
            token: me.$global.token
          }
        }).then(res => {
          if(res.data.data ){
            if(res.data.data.currentIntegral > 0){
              me.integral = res.data.data ? res.data.data.currentIntegral : 0;
              this.personIntegral()
            }else{
              me.pointUseSwitch = false
            }
          }else{
            Toast(res.data.message);
          }
        }).catch(err => {
          Toast(err.message);
        })
      },
    },
    created() {

      let _t = this;
      _t.buyNum = sessionStorage.getItem('buyNum') ? sessionStorage.getItem('buyNum') : 1;
      _t.submitInfo.telephone = sessionStorage.getItem('telephone') ? sessionStorage.getItem('telephone') : '';
      _t.checkArr = sessionStorage.getItem('checkArr') ? JSON.parse(sessionStorage.getItem('checkArr')) : [];
      _t.getPerList();
      var clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      window.onresize = function() {
        var nowClientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        if (clientHeight - nowClientHeight > 300 ) {//因为ios有自带的底部高度
          _t.isKeyPop = false;
          //键盘弹出的事件处理
          document.getElementById("apply").classList.add("focusState");
        }
        else {
          _t.isKeyPop = true;
          //键盘收起的事件处理
          document.getElementById("apply").classList.remove("focusState");
        }
      };
      this.findCommodity() // 查询商品详情
      this.getSysPointConfig()
      this.queryPoint()
      if(this.isSelfSupport){
        this.submitInfo.orderType = '4'
      }
    },
  }
</script>

<style scoped lang="scss">
  .jf_link{
    font-size: 12px;
    color: #b5b5b5;
    a{
      color: #b5b5b5;
      text-decoration: underline;
    }
  }
  .focusState {position: absolute;}
  .so_person{
    .so_person_choose{
      .per_btn{
        width:140px;
        height:30px;
        background:rgba(255,255,255,1);
        border:1px solid rgba(63,212,103,1);
        border-radius:2px;
        font-size:12px;

        font-weight:500;
        color:rgba(63,212,103,1);
        img{
          width: 19px;
          margin-right: 7px;
          vertical-align: middle;
        }
        &.has_not{
          background:rgba(63,212,103,1);
          color: white;

          img{
            width: 18px;
            margin-right: 7px;
          }
        }
      }
      .tag{
        width:39px;
        height:16px;
        background:rgba(63,212,103,1);
        border-radius:2px;
        text-align: center;
        line-height: 16px;
        font-size:10px;

        font-weight:400;
        color:rgba(255,255,255,1);
        left: 0;
        top: 0;
        position: absolute;
      }
      .per_delete{
        width: 15px;
        margin-right: 32px;
      }
      .per_edit{
        width: 19px;
      }
      .name{
        margin-bottom:9px;
      }
      font-size:12px;

      font-weight:400;
      color:rgba(20,18,18,1);
      width:313px;
      background:rgba(255,255,255,1);
      border:1px solid rgba(207,207,207,1);
      border-radius:2px;
      margin: 12px auto 0 auto;
      padding: 25px 10px 13px 10px;
    }
    .sub_title{
      font-size:12px;

      font-weight:400;
      color:rgba(20,18,18,1);
      line-height:35px;
    }
    .title{
      font-size:16px;

      font-weight:bold;
      color:rgba(20,18,18,1);
      margin-right: 14px;
    }
    padding: 11px 11px 13px 11px;
  }
  .so_contact{
    &.so_out{
      /*margin-bottom: 100px;*/
    }
    .so_phone{
      input{
        border: none;
        outline: none;
        font-size:12px;

        font-weight:400;
        color:rgba(174,174,174,1);
      }
      .phone_title{
        font-size:14px;

        font-weight:500;
        color:rgba(20,18,18,1);
        width: 95px;
      }
      margin-top: 17px;
    }
    .sub_title{
      font-size:12px;

      font-weight:400;
      color:rgba(20,18,18,1);
    }
    .title{
      width: 95px;
      font-size:16px;

      font-weight:bold;
      color:rgba(20,18,18,1);
    }
    padding: 11px 11px 13px 11px;
  }
  .so_out_num{
    .so_buy_num{
      img{
        width: 18px;
      }
      span{
        font-size:14px;

        font-weight:bold;
        color:rgba(20,18,18,1);
        margin: 0 9px;
      }
    }
    .sub_title{
      font-size:12px;

      font-weight:400;
      color:rgba(20,18,18,1);
    }
    .title{
      font-size:14px;

      font-weight:500;
      color:rgba(20,18,18,1);
    }
    .price{
      font-size:14px;

      font-weight:bold;
      color:rgba(255,62,110,1);
    }
    padding: 13px 13px 9px 9px;
  }
  .so_out_top{
    .sub{
      font-size:12px;

      font-weight:400;
      color:rgba(20,18,18,1);
      span{
        margin-right: 22px;
      }
    }
    padding: 9px 9px 17px 9px;
    .title{
      font-size:18px;

      font-weight:bold;
      color:rgba(20,18,18,1);
      margin-bottom: 13px;
    }
  }
  .so_out{
    background:rgba(255,255,255,1);
    border-radius:5px;
    margin-bottom: 10px;
  }
  .out{
    top: -20px;
    padding: 0 10px;
  }
  .so_detail_out{
    &.so_per{
      .contentPer{
        .cp_item{
          .edit{
            width: 18px;
          }
          .name{
            width: 68px;
            margin-left: 15px;
          }
          font-size:12px;

          font-weight:400;
          color:rgba(20,18,18,1);
          height: 39px;
          padding: 0 27px 0 24px;
          border-bottom:1px solid rgba(179,179,179,1);
        }
        padding-bottom: 26px;
        max-height: 250px;
        overflow: auto;
      }
      .head{
        .cur_p{
          font-size:14px;
          font-weight:400;
          color:rgba(87,87,87,1);
        }
        padding-right: 21px;
        font-size:18px;

        font-weight:bold;
        color:rgba(51,51,51,1);
      }
      .so_detail{
        bottom: 0;
      }
      height: 100%;
    }
    .so_detail{
      .content{
        height: 42px;
        font-size:14px;

        font-weight:500;
        color:rgba(20,18,18,1);
        padding: 0 24px;
      }
      .head{
        height:44px;
        background:rgba(247,247,247,1);
        line-height: 44px;
        font-size:18px;

        font-weight:bold;
        color:rgba(51,51,51,1);
        padding-left: 21px;
      }
      position: absolute;
      width: 100%;
      bottom: 3px;
      left: 0;
      background:rgba(255,255,255,1);
      border-radius:7px 7px 0px 0px;
      overflow: hidden;
    }
    width: 100%;
    height: calc(100% - 45px);
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background:rgba(0,0,0,0.5);
    z-index: 1000;
  }
  .so_integral{
    padding: 9px 9px 17px 9px;
    margin-bottom: 0;
    .sub{
      font-size:12px;
      font-weight:400;
      color:rgba(20,18,18,1);
      display: flex;
      justify-content: space-between;
      flex-wrap:wrap;
    }
    .title{
      font-size:18px;
      font-weight:bold;
      color:rgba(20,18,18,1);
      margin-bottom: 13px;
    }
    .check{
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: 1px solid #c0c0c0;
      border-radius: 50%;
      cursor: pointer;
    }
    .checked{
      border: none;
      background: url(../../../assets/img/icon-useintegral.png) no-repeat center center/auto 100%;
    }
  }
  .btn_out{
    .integral_f{
      justify-content: space-between;
      align-items: center;
    }
    .integral{
      margin-right: 5px;
      text-align: right;
      li{
        font-size: 12px;
        list-style: none;
      }
      p{
        font-size: 12px;
        color: #FF3E6E;
      }
    }
    .btn_pay{
      width:109px;
      height:30px;
      line-height:30px;
      background:linear-gradient(90deg,rgba(146,228,96,1),rgba(59,211,103,1));
      border-radius:15px;
      font-size:14px;
      box-sizing: border-box;
      font-weight:bold;
      color:rgba(255,255,255,1);
      text-align: center;
    }
    .detail_pop{
      font-size:10px;

      font-weight:400;
      color:rgba(87,87,87,1);
      img{
        width: 10px;
        margin-left: 4px;
      }
    }
    .price{
      font-size:14px;

      font-weight:bold;
      color:rgba(255,62,110,1);
      margin-right: 13px;
    }
    .title{
      font-size:16px;

      font-weight:bold;
      color:rgba(20,18,18,1);
      margin-right: 6px;
    }
    position: fixed;
    height: 45px;
    bottom: 0;
    width: 100%;
    left: 0;
    background:rgba(255,255,255,1);
    box-shadow:0px -1px 2px 0px rgba(6,0,1,0.05);
    padding: 0 21px;
    box-sizing: border-box;
  }
  .full{
    overflow: auto;
    background-color: #EEEEEE;
    padding-bottom: 45px;
  }
  .green_bg{
    height: 20px;
    background-color: #3FD467;
  }
  .van-nav-bar{
    background-color: #3FD467;
    &::after{
      border: none;
    }
  }
</style>
